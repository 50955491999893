const ContactPage = {
  init() {
    /* eslint-disable no-invalid-this */
    $(".accordion__toggle").on("click", function(e) {
      e.stopPropagation();
    });

    $(".js-contact-card .collapse").on("click", function(e) {
      e.stopPropagation();
    });

    $(".js-contact-card").on("click", function() {
      $(this).find(".accordion__toggle").trigger("click");
    });
  }
};

export { ContactPage };
